import auth from "./auth";

const GRAPH_BASE = "https://graph.microsoft.com/beta";
const GRAPH_SCOPES = [
  "user.read",
  "user.readbasic.all",
  "application.readwrite.all",
];

const HTTPPost = "POST";

let accessToken;

export default {
  // Get details of user, and return as JSON
  // https://docs.microsoft.com/en-us/graph/api/user-get?view=graph-rest-1.0&tabs=http#response-1
  async getSelf() {
    let resp = await callGraph("/me");
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  // Search azure ad applications.
  async searchApps(searchString, max = 50) {
    let resp = await callGraph(
      `/applications?$filter=startswith(displayName, '${searchString}')&$count=true&$top=${max}`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  // Create client secret for application.
  async createClientSecret(clientID) {
    let resp = await callGraph(
      `/applications/${clientID}/addPassword`,
      HTTPPost,
      { passwordCredential: { displayName: "created by arnold" } }
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  // Delete client secret for application.
  async deleteClientSecret(clientID, keyID) {
    await callGraph(`/applications/${clientID}/removePassword`, HTTPPost, {
      keyId: keyID,
    });
  },

  // Accessor for access token, only included for demo purposes
  getAccessToken() {
    return accessToken;
  },
};

// Common fetch wrapper (private)
async function callGraph(apiPath, method = "GET", body = {}) {
  // Acquire an access token to call APIs (like Graph)
  // Safe to call repeatedly as MSAL caches tokens locally
  accessToken = await auth.acquireToken(GRAPH_SCOPES);

  let req = {
    method: method,
    headers: {
      authorization: `bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  };

  if (method == HTTPPost) {
    req.body = JSON.stringify(body);
  }

  let resp = await fetch(`${GRAPH_BASE}${apiPath}`, req);

  if (!resp.ok) {
    throw new Error(
      `Call to ${GRAPH_BASE}${apiPath} failed: ${resp.statusText}`
    );
  }

  return resp;
}
