<template>
  <div>
    <div class="title is-5 underline mt-6">
      Search Azure AD Application in Tenant
    </div>

    <input
      v-model="searchTerm"
      type="text"
      class="input"
      placeholder="Application Display Name"
    />

    <div v-if="error" class="notification is-danger mt-4">⚠ {{ error }}</div>

    <table v-if="apps" class="table is-striped is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th>Display Name</th>
          <th>Application ID</th>
          <th>Details (JSON)</th>
          <th>Client Secret</th>
          <th>Ops</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="appResult of apps" :key="appResult.appId">
          <td>{{ appResult.displayName }}</td>
          <td>{{ appResult.appId }}</td>
          <td>
            <button
              class="button is-success is-fullwidth mt-2"
              @click="showAppDetails(appResult, (show = true))"
            >
              <span class="icon">
                <i class="fas fa-circle-info fa-fw" />
              </span>
              <span>App Details</span>
            </button>
          </td>
          <td v-if="appResult.secrets">
            <div v-for="secret of appResult.secrets" :key="secret.id">
              <td align="center" rowspan="2">
                <div class="is-success is-light"><b>{{ secret.decrypted_client_secret }}</b></div>
                <div class="">expires@ {{ secret.expires_at }}</div>
                <a 
                  class="is-danger is-light"
                  @click="deleteClientSecret(appResult, secret, searchTerm)"
                >
                  <span>delete</span>
                </a>
              </td>
            </div>
          </td>
          <td v-else>-</td>
          <td>
            <button
              class="button md-raised is-fullwidth mt-2"
              @click="createNewClientSecret(appResult, searchTerm)"
            >
              <span class="icon">
                <i class="fas fa-key fa-fw" />
              </span>
              <span>Create Client Secret</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <DetailModal
      :content="JSON.stringify(appDetails, null, 2)"
      title="App Details"
      :shown="isShowAppDetails"
      @close="isShowAppDetails = false"
    />
  </div>
</template>

<script>
import _ from "lodash";
import graph from "../services/graph";
import auth from "../services/auth";
import DetailModal from "./DetailModal.vue";
import supabase from "../services/supabase";
export default {
  name: "App",
  components: { DetailModal },
  props: {
    app: {
      type: Object,
      required: true,
      default: null,
    },
    accessToken: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      apps: null,
      searchTerm: null,
      error: null,
      isShowAppDetails: false,
      appDetails: null,
    };
  },
  watch: {
    // Watch the search field and run a Graph search with a debounce
    searchTerm(newValue) {
      if (newValue.trim()) {
        this.searchDebounce(newValue);
      }
    },
  },
  methods: {
    // Lodash debounce wrapper around the HTTP call to the Graph
    searchDebounce: _.debounce(async function (searchString) {
      if (!this.accessToken) {
        return;
      }
      try {
        let searchRes = await graph.searchApps(searchString, 50);
        this.apps = searchRes.value;

        // append secret
        (async () => {
          this.apps = await Promise.all(
            searchRes.value.map(async (app) => {
              app.secrets = await supabase.getClientSecret(app.appId);
              return app;
            })
          );
        })();
      } catch (err) {
        this.error = err.toString();
      }
    }, 300),

    // show app detail
    showAppDetails(appResult, show) {
      this.appDetails = appResult;
      this.isShowAppDetails = show;
    },

    // create new client secret
    createNewClientSecret(appResult, searchString) {
      (async () => {
        // call graph api to create client secret
        let secret = await graph.createClientSecret(appResult.id);

        // update view
        this.searchDebounce(searchString);

        // store secret to supabase
        let result = await supabase.storeClientSecret(
          appResult,
          secret,
          auth.user()
        );
        if (result) {
          console.log(result);
          return;
        }

        return secret;
      })();
    },

    // delete client secret
    deleteClientSecret(appResult, secret, searchString) {
      (async () => {
        // call graph api to delete client secret
        _ = await graph.deleteClientSecret(appResult.id, secret.secret_id);

        // delete secret from supabase
        _ = await supabase.deleteClientSecret(secret.id);

        // update view
        this.searchDebounce(searchString);
      })();
    },
  },
};
</script>
