const { createClient } = require("@supabase/supabase-js");

const supabase = createClient(
  process.env.VUE_APP_PUBLIC_SUPABASE_URL,
  process.env.VUE_APP_PUBLIC_SUPABASE_ANON_KEY
);

export default {
  // get client secret.
  async getClientSecret(clientID) {
    const { data, error } = await supabase
      .rpc("fetch_aad_client_secrets")
      .eq("client_id", clientID)
      .eq("deleted", false);
    if (error) {
      console.log(error);
      return;
    }
    return data;
  },

  // store client secret.
  async storeClientSecret(app, secret, user) {
    const { result, error } = await supabase.from("aad_client_secrets").insert({
      tenant_id: user.tenantId,
      client_id: app.appId,
      client_secret: secret.secretText,
      secret_id: secret.keyId,
      secret_description: secret.displayName,
      expires_at: secret.endDateTime,
      deleted: false,
    });
    if (error) {
      console.log(error);
      return;
    }
    return result;
  },

  // delete client secret.
  async deleteClientSecret(id) {
    const { result, error } = await supabase
      .from("aad_client_secrets")
      .update({
        deleted: true,
        updated_at: new Date().toISOString(),
      })
      .eq("id", id);
    if (error) {
      console.log(error);
      return;
    }
    return result;
  },
};
